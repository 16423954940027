<template>
    <div class="body_wrap">
        <div class="line"></div>
        <div style="background:#f5f5f5;">
            <section>
                <el-steps :active="active" finish-status="success" align-center>
                    <!-- <el-step :title="orderEntry === 0 ? '查看购物车' : orderEntry * 1 === 1 ? '选择产品' : '选择商家'"></el-step> -->
                    <el-step :title="fastList.fastFlag==0 ? '选择产品' : fastList.fastFlag==1 ? '选择产品' : '选择商家'"></el-step>
                    <el-step title="确认订单信息"></el-step>
                    <el-step title="成功提交订单"></el-step>
                </el-steps>
                <Address @selected="selectAddress" @sendInfo="change"/>
                <div class="product-list">
                    <el-row class="product-header">
                        <el-col :span="24">
                            <span class="product-header-tip">产品清单</span>
                        </el-col>
                    </el-row>
                    <el-row class="product-table-head">
                        <el-col :span="12">
                            <div>产品信息</div>
                        </el-col>
                        <el-col :span="4">
                            <div>单价</div>
                        </el-col>
                        <el-col :span="4">
                            <div>数量</div>
                        </el-col>
                        <el-col :span="4">
                            <div>小计</div>
                        </el-col>
                    </el-row>



                    <div class="fastOrder" v-if="isFastOrder">
                        <!-- 快速下单·展示 -->
                        <div class="fastOrder-top">
                                {{fastList.storeName}}
                        </div>
                        <div class="fastOrder-bottom">
                            <img :src="fastList.img | imagePath" alt="">
                            <div>{{fastList.name}}</div>
                            <div>{{fastList.price}}</div>
                            <div>1</div>
                            <div>{{fastList.price}}</div>
                        </div>
                    </div>



                    <el-row class="product-body" v-for="(item, index) in storeList" :key="index">
                        <el-row class="product-shop">
                            <el-col :span="24">
                                <div class="product-shop-name" v-text="item.storeName"></div>
                            </el-col>
                        </el-row>



                        <div class="product-items-wrap" v-if="isdiDetailOrder">
                            <!-- 详情订单展示 -->
                            <el-row class="product-item" v-for="(goods, inx) in item.cartItemList" :key="inx">
                                <el-col :span="12">
                                    <el-row>
                                        <el-col :span="6">
                                            <img :src="goods.productPicUrl | imagePath" alt>
                                        </el-col>
                                        <el-col :span="18">
                                            <p :title="goods.productName" class="product-name"
                                               v-text="goods.productName"></p>
                                            <p>产品编号:{{goods.productSn}}</p>
                                            <template v-for="(p,j) in attr">
                                                <p>
                                                      {{j}}: {{p}}<br>
                                                </p>
                                            </template>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="4">
                                    <div class="product-price">￥{{goods.productPrice | price}}</div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="product-num" v-text="goods.productQuantity"></div>
                                </el-col>
                                <el-col :span="4">
                                    <div
                                            class="product-total-price"
                                    >￥{{(goods.productQuantity * goods.productPrice) | price}}
                                    </div>
                                </el-col>
                            </el-row>
                        </div>



                    </el-row>
                    <el-row v-if="0">
                        <div class="receipt">
                            <el-row>
                                <el-col :span="24">
                                    <el-checkbox v-model="checked">需要开票</el-checkbox>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">抬头类型：</span>
                                    <el-radio v-model="radio" label="1">企业</el-radio>
                                    <el-radio v-model="radio" label="2">个人</el-radio>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">发票类型：</span>
                                    <el-radio v-model="radio" label="1">增值税专用发票</el-radio>
                                    <el-radio v-model="radio" label="2">增值税普通发票</el-radio>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="24" :offset="1">
                                    <span class="receipt-label">发票信息</span>
                                </el-col>
                            </el-row>
                            <div v-show="radio === 1">
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">识别码：</span>
                                        <el-input type="text" placeholder="请输入识别码"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">注册地址：</span>
                                        <el-input type="text" placeholder="请输入注册地址"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">注册电话：</span>
                                        <el-input type="text" placeholder="请输入注册电话"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">开户银行：</span>
                                        <el-input type="text" placeholder="请输入开户银行"></el-input>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">银行账号：</span>
                                        <el-input type="text" placeholder="请输入银行账号"></el-input>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-show="radio ==2">
                                <el-row :gutter="20">
                                    <el-col :span="24" :offset="1" class="flex align-center">
                                        <span class="receipt-label">抬头名称：</span>
                                        <el-input type="text" placeholder="请输入抬头名称"></el-input>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-row>
                    <el-row class="product-footer">
                        <div class="checkWrap">
                            <input type="checkbox" class="ipt1" id="checkbox"
                             :checked="agree" @change="agreeHandler">
                          <label for="checkbox" class="lab1"></label>
                          <span @mouseover="mouseOver" @mouseleave="mouseLeave">需要业务单</span>
                          <img src="../../assets/icon/tishi3.png" alt="" @mouseover="mouseOver" @mouseleave="mouseLeave">
                          <div class="popPrompt" v-show="ispopPrompt" >
                              <p>
                                  <span>1、</span>
                                  <span>
                                  业务单用于您和商家交易作为维权凭证
                                  </span>
                              </p>
                              <p>
                                  <span>2、</span>
                                  <span>业务单可查看您的交易项目明细（包括类型、项目内容、材质、规格、单位、数量、金额、制作要求等）</span>

                              </p>
                              <p>
                                  <span>3、</span>
                                  <span>
                                      勾选"需要业务单"您的订单将生成待付款订单，确认核查业务单无误后再进行付款
                                  </span>
                              </p>
                          </div>
                        </div>
                        <p>给卖家留言：</p>
                        <el-col :span="12">
                            <el-input type="textarea" :rows="4" placeholder="请输入内容(0-100)"
                                      v-model="param.remark"></el-input>
                        </el-col>
                    </el-row>
                </div>

                <el-row class="product-submit" :gutter="20" >
                    <el-col :span="10" :offset="10" v-if="isFastOrder">
                        共1件产品&nbsp;&nbsp;&nbsp; 产品总价：￥{{fastList.price | price}}&nbsp;&nbsp;&nbsp; 订单总金额
                        <span>￥{{fastList.price | price}}</span>
                    </el-col>
                    <el-col :span="10" :offset="10" v-if="isdiDetailOrder">
                        共{{totalNumber}}件产品&nbsp;&nbsp;&nbsp; 产品总价：￥{{totalPrice | price}}&nbsp;&nbsp;&nbsp; 订单总金额
                        <span>￥{{totalPrice | price}}</span>
                    </el-col>
                    <el-col
                            v-show="!agree"
                            class="product-submit-btn"
                            :span="4"
                            style="cursor:pointer;"
                            @click.native="submitOrder"
                    >提交订单
                    </el-col>
                    <el-col
                            v-show="agree"
                            class="product-submit-btn"
                            :span="4"
                            style="cursor:pointer;"
                            @click.native="createOrder"
                    >创建订单
                    </el-col>
                </el-row>

                <el-row class="tip-warp">
                    <el-col :span="24">温馨提示：</el-col>
                    <el-col :span="24">
                        <span class="zeng f12">赠</span>
                        提交订单并确认收货成功后，将同时赠送您
                        <span class="jf">{{score}}积分</span> ，可用于孔雀有礼
                        <a href="https://www.kqwad.com/pointsmall/index.html">积分商城</a>礼品兑换。
                    </el-col>
                </el-row>
            </section>
        </div>
<!--        <Footer></Footer>-->
    </div>
</template>

<script>
    import Address from './address/index.vue'
    import {mapMutations, mapGetters, mapActions} from 'vuex'
    import {getUserAddress, delAddress} from '../../api/api'
    /*window.addEventListener("popstate", function(){浏览器返回的回调时间
        //doSomething
    }, false)*/
    export default {
        name: 'order',
        components: {
            Address
        },
        data() {
            return {
                agree: false,
                ispopPrompt:false, //是否需要业务单提示信息
                checked1:false,
                checked2:false,
                fastArr:{}, //首页下单订单数据
                fastList:JSON.parse(localStorage.getItem("fastList")), //首页下单标识
                isFastOrder:false, //首页快速下单是否显示
                isdiDetailOrder:false, //详情下单是否展示
                active: 1,
                attr: [],
                radio: 1,
                checked: true,
                totalNumber: 0,
                totalPrice: 0,
                score: 0,
                storeList: [],
                param: {
                    billType: 0,
                    cartTTos: [],
                    headType: 0,
                    invoiceId: -1,
                    invoiceTitle: '',
                    number: 0,
                    remark: '',
                    userConsigneeId: ''
                }
            }
        },
        computed: {
            ...mapGetters(['orderEntry', 'cartItems', 'product', 'store'])
        },
        // destroyed(){ //清出flag标识
        //     localStorage.removeItem('fastList');
        // },
        mounted() {
          if(this.fastList.fastFlag=="0"){//如果从首页快速下单,阻止从vuex获取数据
             let fastList=this.fastList;
             let fastFlag=fastList.fastFlag;
             this.score=Math.floor(this.fastList.price);
            if (fastFlag=="0") {
                this.isFastOrder=true;
                this.isdiDetailOrder=false;
                // this.getfastArr();
                return;
            }
           }
           if(this.fastList.fastFlag=="2"){//一键支付
            let fastList=this.fastList;
             let fastFlag=fastList.fastFlag;
             this.score=Math.floor(this.fastList.price);
              this.isFastOrder=true;
                this.isdiDetailOrder=false;
                // this.getfastArr();
                return;
           }  else{ ////直接从商品详情页面进入下单
                this.isFastOrder=false;
                this.isdiDetailOrder=true;
                    if (this.orderEntry * 1 === 0) {
                        //从购物车进入下单页面
                        this.storeList = this.cartItems;
                        // console.log(this.cartItems,6666666666)
                        this.calcPrice();
                    } else {
                        //直接从商品详情页面进入下单
                        const item = Object.assign({}, this.store, {cartItemList: [this.product]});
                        this.storeList = [item];
                        // console.log(this.storeList,88888888888)
                        this.totalNumber = this.product.productQuantity;
                        this.totalPrice = this.product.productQuantity * this.product.productPrice;
                        this.score = Math.floor(this.totalPrice);
                        const skuId = this.product.skuId;
                        if (skuId !== null && skuId !== "") {
                            //  获取产品属性
                            this.getAttr(skuId);
                        }
                    }
            }


        },
        methods: {
              mouseOver() {
                this.ispopPrompt=true;
              },
              mouseLeave() {
                this.ispopPrompt=false;
              },
            agreeHandler() {
            this.agree = !this.agree;
            },
            change(value,value1){
                this.checked1=value;
                this.checked2=value1;
            },
            ...mapActions(['queryCartList']),
            ...mapMutations({savePay: 'PAY_INFO'}),
            // getfastArr(){ //快速下单订单确认页数据
            // // console.log(this.fastList.id,77)
            //     this.$http.get('product/getById?id='+this.fastList.id).then(res => {
            //         this.fastArr = res.data.resdata;
            //         // this.fastList.pc_url
            //         console.log(res.data.resdata)
            //     })
            // },

            selectAddress(e) {
                const {id, selected} = e
                if (selected) this.param.userConsigneeId = id
                else this.param.userConsigneeId = ''
            },
            getAttr(skuId) {
                this.$http.get('tOrder/order?skuId=' + skuId).then(res => {
                    if (res.data.code === 200 || res.data.code === 0) {
                        this.attr = res.data.data;
                    }
                });
            },
            calcPrice() {
                let price = 0
                let total = 0
                this.cartItems.forEach(item => {
                    price += item.cartItemList.reduce((p, c) => {
                        return p + (c.checked && c.productPrice * c.productQuantity)
                    }, 0)
                    total += item.cartItemList.reduce((p, c) => {
                        return p + (c.checked ? 1 : 0)
                    }, 0)
                })
                this.totalNumber = total
                this.totalPrice = price
                this.score = Math.floor(this.totalPrice)
            },
            submitOrder() {
                // console.log(this.orderEntry*1,555555)
                 if (!this.checked1 && !this.checked2) {
                      this.$message({
                        message: '请勾选收货配送方式!',
                        type: 'error'
                    })
                    return
                 }
                 if (this.checked2 && this.param.userConsigneeId=="") {
                     this.$message({
                        message: '请选择收货地址!',
                        type: 'error'
                    })
                    return
                 }
                    if (this.fastList.fastFlag==0 ||this.fastList.fastFlag==2) { //首页快速下单
                        const param = {
                                price: this.fastList.price, //价格
                                remark: this.param.remark, //订单确认的用户留言
                                // skuId: this.fastArr.id,
                                storeId: this.fastList.storeId,
                                sn: this.fastList.id,
                                iNeed: this.fastList.info, //一键支付的备注
                                userConsigneeId: this.param.userConsigneeId, //地址
                                businessStatus:"0" //是否需要业务单，0=>需要，1=>不需要
                            }
                            if(this.checked1){
                                param.userConsigneeId="";
                            }
                            this.$http.post('tOrder/addOrder', param).then(res => {
                                const {code, data} = res.data
                                if (code * 1 === 0) {
                                    this.queryCartList()
                                    this.savePay(data[0])
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: '下单成功',
                                        onClose: () => {
                                            const {subject} = data[0]
                                            this.$router.push({name: 'paystatus', params: {orderSn: subject}})
                                        }
                                    })
                                }
                            })
                    }

                    else{
                        const param = {
                            billType: 0,
                            headType: 1,
                            invoiceId: '',
                            invoiceTitle: '',
                            number: this.product.productQuantity,
                            price: this.product.productPrice,
                            remark: this.param.remark,
                            skuId: this.product.skuId,
                            storeId: this.store.id,
                            sn: this.product.id,
                            userConsigneeId: this.param.userConsigneeId,
                            businessStatus:"0" //是否需要业务单

                        }
                        if(this.checked1){
                            param.userConsigneeId="";
                        }

                        this.$http.post('tOrder/addOrder', param).then(res => {
                            const {code, data} = res.data
                            if (code * 1 === 0) {
                                this.queryCartList()
                                this.savePay(data[0])
                                this.$message({
                                    type: 'success',
                                    duration: 1000,
                                    message: '下单成功',
                                    onClose: () => {
                                        const {subject} = data[0]
                                        this.$router.push({name: 'paystatus', params: {orderSn: subject}})
                                    }
                                })
                            }
                        })
                    }

            },
            createOrder(){
               if (!this.checked1 && !this.checked2) {
                      this.$message({
                        message: '请勾选收货配送方式!',
                        type: 'error'
                    })
                    return
                 }
                 if (this.checked2 && this.param.userConsigneeId=="") {
                     this.$message({
                        message: '请选择收货地址!',
                        type: 'error'
                    })
                    return
                 }

                    if (this.fastList.fastFlag==0 ||this.fastList.fastFlag==2) { //首页快速下单

                        const param = {
                                // billType: 0,
                                // headType: 1,
                                // invoiceId: '',
                                // invoiceTitle: '',
                                // number: this.product.productQuantity,
                                price: this.fastList.price, //价格
                                remark: this.param.remark, //订单确认页留言
                                // skuId: this.fastArr.id,
                                storeId: this.fastList.storeId,
                                sn: this.fastList.id,
                                iNeed: this.fastList.info, //一键支付我备注信息
                                userConsigneeId: this.param.userConsigneeId, //地址
                                businessStatus:"1" //是否需要业务单，0=>需要，1=>不需要
                            }
                            if(this.checked1){
                                param.userConsigneeId="";
                            }
                            this.$http.post('tOrder/addOrder', param).then(res => {
                                const {code, data} = res.data
                                if (code * 1 === 0) {
                                    this.queryCartList()
                                    this.savePay(data[0])
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: '正在创建订单!',
                                        onClose: () => {
                                            const {subject} = data[0]

                                            this.$router.push("/purchaseOrder");
                                            // this.$router.push({name: 'paystatus', params: {orderSn: subject}})
                                        }
                                    })
                                }
                            })
                    }

                    else{
                        const param = {
                            billType: 0,
                            headType: 1,
                            invoiceId: '',
                            invoiceTitle: '',
                            number: this.product.productQuantity,
                            price: this.product.productPrice,
                            remark: this.param.remark,
                            skuId: this.product.skuId,
                            storeId: this.store.id,
                            sn: this.product.id,
                            userConsigneeId: this.param.userConsigneeId,
                            businessStatus:"1" //是否需要业务单，0=>需要，1=>不需要
                        }
                        if(this.checked1){
                            param.userConsigneeId="";
                        }

                        this.$http.post('tOrder/addOrder', param).then(res => {
                            const {code, data} = res.data
                            if (code * 1 === 0) {
                                this.queryCartList()
                                this.savePay(data[0])
                                this.$message({
                                    type: 'success',
                                    duration: 1000,
                                    message: '正在创建订单!',
                                    onClose: () => {
                                        const {subject} = data[0]
                                            this.$router.push("/purchaseOrder");
                                        // this.$router.push({name: 'paystatus', params: {orderSn: subject}})
                                    }
                                })
                            }
                        })
                    }

            }
        },


    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/common.scss';

    @mixin icon($m) {
        $url: '../../assets/image/' + $m;
        @include bgImage($url);
    }

    section {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 0;
        padding-top: 20px;
        // overflow: hidden;
        clear: both;

        .product-list {
            margin-top: 20px;
            background-color: #fff;
            padding: 20px;
            box-sizing: border-box;

            .product-header {
                .product-header-tip {
                    padding-left: 10px;
                    border-left: 3px solid $color168;
                }
            }

            .product-table-head {
                margin: 10px 0;
                background-color: #f5f5f5;
                padding: 10px;
            }

            .product-body {
                border: 1px solid #e6e6e6;

                .product-shop {
                    padding: 10px;
                    background: #f5f5f5;
                }

                .product-items-wrap {
                    border-bottom: 1px solid #e6e6e6;

                    .product-item {
                        margin: 20px;

                        img {
                            width: 89px;
                            height: 89px;
                        }

                        .product-name {
                            width: 220px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        p {
                            font-size: 12px;
                            color: #999;
                            line-height: 23px;

                            &.product-name {
                                font-size: 15px;
                                color: #333;
                            }
                        }

                        .product-price {
                            font-size: 15px;
                            color: #333;
                        }

                        .product-num {
                            font-size: 14px;
                            color: #333;
                        }

                        .product-total-price {
                            color: #ff0036;
                            font-size: 15px;
                        }
                    }
                }
            }

            // 发票
            .receipt {
                padding: 20px;

                .el-row {
                    margin-bottom: 10px;

                    .receipt-label {
                        font-size: 14px;
                        color: #666;
                        min-width: 90px;

                        + .el-input {
                            max-width: 300px;
                        }
                    }
                }
            }

            .product-footer {
                box-sizing: border-box;

                p {
                    margin: 20px 0;
                }

                .remark-num-wrap {
                    position: relative;

                    .remark-num {
                        position: absolute;
                        bottom: -55px;
                        left: 18px;
                    }
                }
                .checkWrap{
                    height: 60px;
                    display: flex;
                    align-items: center;
                     input:checked + label {
                    // 这段代码是当input选中时,给input的相邻标签label加一个背景图片

                    background-image: url('../../assets/icon/gouxuan.png');
                    background-repeat: no-repeat;
                    background-position: 2px 2px;
                    }
                    .ipt1 {
                    display: none;
                    float: left;
                    background: url("../../assets/icon/shoujihao1.png") no-repeat 13px 13px;
                    }
                    .lab1 {
                    float: left;
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #ccc;
                    top: 22px;
                    }
                    >:nth-child(3){
                        margin-left: 30px;
                    }
                    img{
                        display: block;
                        width: 30px;
                        height: 20px;
                        padding-left: 10px;
                    }
                    .popPrompt{
                        width: 450px;
                        height:150px;
                        background: #333;
                        color:#fff;
                        border-radius: 4px;
                        padding: 10px;
                        margin-left: 20px;
                        p{
                            margin: 0;
                            line-height: 26px;
                            display: flex;
                        }
                    }
                }
            }
        }

        .product-submit {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 20px;
            background-color: #fff;
            margin: 20px 0;
            height: 50px;
            box-sizing: border-box;
            line-height: 50px;

            span {
                color: red;
            }

            .product-submit-btn {
                color: #fff;
                text-align: center;
                background-color: #d82229;
            }
        }

        .tip-warp {
            padding: 5px;
            font-size: 14px;

            .el-col {
                margin-bottom: 10px;
            }

            .zeng {
                width: 40px;
                padding: 2px 5px;
                display: inline-block;
                color: #fff;
                text-align: center;
                background-color: #d82229;
            }

            .jf {
                color: red;
            }

            a {
                color: #d82229;
            }
        }
    }

    .fastOrder{
        width: 100%;
        height: 150px;
        // background: pink;
        display: flex;
        flex-direction: column;
        border: 1px solid #e6e6e6;
        .fastOrder-top{
            line-height: 41px;
            background: #f5f5f5;
            padding-left: 10px;
        }
        .fastOrder-bottom{
            padding-left: 10px;
            display: flex;
            flex: 1;
            align-items: center;
            img{
                width: 90px;
                height: 90px;
            }
            >div{
                 overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            >:nth-child(2){
                width: 200px;
                margin-left: 30px;
            }
            >:nth-child(3){
                width: 200px;
                margin-left: 165px;
                text-align: center;
            }
            >:nth-child(4){
                width: 100px;
                margin-left: 40px;
                text-align: center;
            }
            >:nth-child(5){
                width: 100px;
                margin-left: 90px;
                text-align: center;
                color: #ff0036;
            }
        }
    }


//     /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
//     background: #d82229;
//     color: #d82229;
//     border: #d82229;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     &:hover{
//         color: #d82229;
//     }
// }

// /deep/ .el-checkbox__input .is-focus .el-checkbox__inner{
//     border: #d82229;
// }
// /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,.el-checkbox__input.is-indeterminate .el-checkbox__inner{
//     background-color: #d82229;
//     border-color: #d82229;
// }
// /deep/ .el-checkbox__inner:hover{
//     border-color: #d82229;
// }
// /deep/ .el-checkbox__input .is-focus .el-checkbox__inner{
// border-color: #d82229;
// }
// //移出复选框
// /deep/ .el-button--success.is-disabled, .el-button--success.is-disabled:hover, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:active{
//     color: #d82229;

// }
</style>
